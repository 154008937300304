import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Terms from "./style"
export default function PrivacyPolicy() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">Privacy Policy</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.Text>Last updated: January 06, 2024</Terms.Text>
              <Terms.Text>
                Welcome to Monsai’s Privacy Policy. Please take a moment to also
                read our <a href="/terms/">Terms</a>.
              </Terms.Text>
              <Terms.Text>
                This privacy notice for Monsai ("<b>we</b>," "<b>us</b>," or "
                <b>our</b>"), describes how and why we might collect, store,
                use, and/or share ("<b>process</b>") your information when you
                use our services ("<b>Services</b>"), such as when you:
              </Terms.Text>
              <Terms.List>
                <Terms.ListItem>
                  Download and use our mobile application (Monsai), or any other
                  application of ours that links to this privacy notice
                </Terms.ListItem>
                <Terms.ListItem>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </Terms.ListItem>
              </Terms.List>
              <Terms.Text>
                <b>Questions or concerns?</b> Reading this privacy notice will
                help you understand your privacy rights and choices. If you do
                not agree with our policies and practices, please do not use our
                Services. If you still have any questions or concerns, please
                contact us at{" "}
                <a href="mailto: privacy@monsaiapp.com">
                  privacy@monsaiapp.com
                </a>
              </Terms.Text>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  SUMMARY OF KEY POINTS
                </Terms.TitleSmall>
                <Terms.Text>
                  <b>
                    <i>
                      This summary provides key points from our privacy notice,
                      but you can find out more details about any of these
                      topics by clicking the link following each key point or by
                      using our <a href="#toc">table of contents</a> below to
                      find the section you are looking for.
                    </i>
                  </b>
                </Terms.Text>
                <Terms.Text>
                  <b>What personal information do we process?</b> When you
                  visit, use, or navigate our Services, we may process personal
                  information depending on how you interact with us and the
                  Services, the choices you make, and the products and features
                  you use. Learn more about{" "}
                  <a href="#personalinfo">
                    personal information you disclose to us.
                  </a>
                </Terms.Text>
                <Terms.Text>
                  <b>Do we process any sensitive personal information?</b> We do
                  not process sensitive personal information.
                </Terms.Text>
                <Terms.Text>
                  <b>Do we receive any information from third parties?</b> We do
                  not receive any information from third parties.
                </Terms.Text>
                <Terms.Text>
                  <b>How do we process your information?</b>We process your
                  information to provide, improve, and administer our Services,
                  communicate with you, for security and fraud prevention, and
                  to comply with law. We may also process your information for
                  other purposes with your consent. We process your information
                  only when we have a valid legal reason to do so. Learn more
                  about <a href="#infouse">how we process your information.</a>
                </Terms.Text>
                <Terms.Text>
                  <b>
                    In what situations and with which parties do we share
                    personal information?
                  </b>{" "}
                  We may share information in specific situations and with
                  specific third parties. Learn more about{" "}
                  <a href="#whoshare">
                    when and with whom we share your personal information.
                  </a>
                </Terms.Text>
                <Terms.Text>
                  <b>How do we keep your information safe?</b>We have
                  organizational and technical processes and procedures in place
                  to protect your personal information. However, no electronic
                  transmission over the internet or information storage
                  technology can be guaranteed to be 100% secure, so we cannot
                  promise or guarantee that hackers, cybercriminals, or other
                  unauthorized third parties will not be able to defeat our
                  security and improperly collect, access, steal, or modify your
                  information. Learn more about{" "}
                  <a href="#infosafe">how we keep your information safe.</a>
                </Terms.Text>
                <Terms.Text>
                  <b>What are your rights?</b>Depending on where you are located
                  geographically, the applicable privacy law may mean you have
                  certain rights regarding your personal information. Learn more
                  about <a href="#privacyrights">your privacy rights.</a>
                </Terms.Text>
                <Terms.Text>
                  <b>How do you exercise your rights?</b>The easiest way to
                  exercise your rights is by contacting us. We will consider and
                  act upon any request in accordance with applicable data
                  protection laws.
                </Terms.Text>
                <Terms.Text>
                  Want to learn more about what we do with any information we
                  collect? <a href="#toc">Review the privacy notice in full.</a>
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="toc">
                <Terms.TitleSmall as="h5">TABLE OF CONTENTS</Terms.TitleSmall>
                <Terms.List>
                  <Terms.Text>
                    <a href="#infocollect">
                      1. WHAT INFORMATION DO WE COLLECT?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#infouse">
                      2. HOW DO WE PROCESS YOUR INFORMATION?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#legalbases">
                      3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#whoshare">
                      4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#sociallogins">
                      5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#inforetain">
                      6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#infosafe">
                      7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#infominors">
                      8. DO WE COLLECT INFORMATION FROM MINORS?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#privacyrights">
                      9. WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#DNT">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#uslaws">
                      11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                      RIGHTS?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#policyupdates">
                      12. DO WE MAKE UPDATES TO THIS NOTICE?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#contact">
                      13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#request">
                      14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </a>
                  </Terms.Text>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="infocollect">
                <Terms.TitleSmall as="h5">
                  1. WHAT INFORMATION DO WE COLLECT?
                </Terms.TitleSmall>
                <Terms.Subtitle id="personalinfo">
                  Personal information you disclose to us
                </Terms.Subtitle>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> We collect personal information that you
                    provide to us.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  We collect personal information that you voluntarily provide
                  to us when you register on the Services, express an interest
                  in obtaining information about us or our products and
                  Services, when you participate in activities on the Services,
                  or otherwise when you contact us.
                </Terms.Text>
                <Terms.Text>
                  <b>Personal Information Provided by You.</b> The personal
                  information that we collect depends on the context of your
                  interactions with us and the Services, the choices you make,
                  and the products and features you use. The personal
                  information we collect may include the following:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>names</Terms.ListItem>
                  <Terms.ListItem>email addresses</Terms.ListItem>
                  <Terms.ListItem>passwords</Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  <b>Sensitive Information.</b> We do not process sensitive
                  information.
                </Terms.Text>
                <Terms.Text>
                  <b>Social Media Login Data.</b> We may provide you with the
                  option to register with us using your existing social media
                  account details, like your Facebook, Twitter, or other social
                  media account. If you choose to register in this way, we will
                  collect the information described in the section called "HOW
                  DO WE HANDLE YOUR SOCIAL LOGINS?" below.
                </Terms.Text>
                <Terms.Text>
                  <b>Application Data.</b> If you use our application(s), we
                  also may collect the following information if you choose to
                  provide us with access or permission:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    <i>Mobile Device Data.</i> We automatically collect device
                    information (such as your mobile device ID, model, and
                    manufacturer), operating system, version information and
                    system configuration information, device and application
                    identification numbers, browser type and version, hardware
                    model Internet service provider and/or mobile carrier, and
                    Internet Protocol (IP) address (or proxy server). If you are
                    using our application(s), we may also collect information
                    about the phone network associated with your mobile device,
                    your mobile device's operating system or platform, the type
                    of mobile device you use, your mobile device's unique device
                    ID, and information about the features of our application(s)
                    you accessed.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    <i>Push Notifications.</i> We may request to send you push
                    notifications regarding your account or certain features of
                    the application(s). If you wish to opt out from receiving
                    these types of communications, you may turn them off in your
                    device's settings.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  This information is primarily needed to maintain the security
                  and operation of our application(s), for troubleshooting, and
                  for our internal analytics and reporting purposes.
                </Terms.Text>
                <Terms.Text>
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </Terms.Text>
                <Terms.Subtitle>
                  Information automatically collected
                </Terms.Subtitle>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> Some information -- such as your Internet
                    Protocol (IP) address and/or browser and device
                    characteristics -- is collected automatically when you visit
                    our Services.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services, and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes.
                </Terms.Text>
                <Terms.Text>The information we collect includes:</Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    <i>Log and Usage Data.</i> Log and usage data is
                    service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you
                    access or use our Services and which we record in log files.
                    Depending on how you interact with us, this log data may
                    include your IP address, device information, browser type,
                    and settings and information about your activity in the
                    Services (such as the date/time stamps associated with your
                    usage, pages and files viewed, searches, and other actions
                    you take such as which features you use), device event
                    information (such as system activity, error reports
                    (sometimes called "crash dumps"), and hardware settings).
                  </Terms.ListItem>
                  <Terms.ListItem>
                    <i>Device Data.</i> We collect device data such as
                    information about your computer, phone, tablet, or other
                    device you use to access the Services. Depending on the
                    device used, this device data may include information such
                    as your IP address (or proxy server), device and application
                    identification numbers, location, browser type, hardware
                    model, Internet service provider and/or mobile carrier,
                    operating system, and system configuration information.
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="infouse">
                <Terms.TitleSmall as="h5">
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> We process your information to provide,
                    improve, and administer our Services, communicate with you,
                    for security and fraud prevention, and to comply with law.
                    We may also process your information for other purposes with
                    your consent.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  <b>
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                  </b>
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    <b>
                      To facilitate account creation and authentication and
                      otherwise manage user accounts.
                    </b>{" "}
                    We may process your information so you can create and log in
                    to your account, as well as keep your account in working
                    order.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    <b>
                      To deliver and facilitate delivery of services to the
                      user.
                    </b>
                    We may process your information to provide you with the
                    requested service.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    <b>To request feedback.</b> We may process your information
                    when necessary to request feedback and to contact you about
                    your use of our Services.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    <b>
                      To evaluate and improve our Services, products, marketing,
                      and your experience.
                    </b>{" "}
                    We may process your information when we believe it is
                    necessary to identify usage trends, determine the
                    effectiveness of our promotional campaigns, and to evaluate
                    and improve our Services, products, marketing, and your
                    experience.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    <b>To identify usage trends.</b> We may process information
                    about how you use our Services to better understand how they
                    are being used so we can improve them.
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="legalbases">
                <Terms.TitleSmall as="h5">
                  3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> We only process your personal information
                    when we believe it is necessary and we have a valid legal
                    reason (i.e., legal basis) to do so under applicable law,
                    like with your consent, to comply with laws, to provide you
                    with services to enter into or fulfill our contractual
                    obligations, to protect your rights, or to fulfill our
                    legitimate business interests.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  <b>
                    <i>
                      <u>
                        If you are located in Canada, this section applies to
                        you.
                      </u>
                    </i>
                  </b>
                </Terms.Text>
                <Terms.Text>
                  We may process your information if you have given us specific
                  permission (i.e., express consent) to use your personal
                  information for a specific purpose, or in situations where
                  your permission can be inferred (i.e., implied consent). You
                  can <a href="#withdrawconsent">withdraw your consent</a> at
                  any time.
                </Terms.Text>
                <Terms.Text>
                  In some exceptional cases, we may be legally permitted under
                  applicable law to process your information without your
                  consent, including, for example:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    If collection is clearly in the interests of an individual
                    and consent cannot be obtained in a timely way
                  </Terms.ListItem>
                  <Terms.ListItem>
                    For investigations and fraud detection and prevention
                  </Terms.ListItem>
                  <Terms.ListItem>
                    For business transactions provided certain conditions are
                    met
                  </Terms.ListItem>
                  <Terms.ListItem>
                    If it is contained in a witness statement and the collection
                    is necessary to assess, process, or settle an insurance
                    claim
                  </Terms.ListItem>
                  <Terms.ListItem>
                    For identifying injured, ill, or deceased persons and
                    communicating with next of kin
                  </Terms.ListItem>
                  <Terms.ListItem>
                    If we have reasonable grounds to believe an individual has
                    been, is, or may be victim of financial abuse
                  </Terms.ListItem>
                  <Terms.ListItem>
                    If it is reasonable to expect collection and use with
                    consent would compromise the availability or the accuracy of
                    the information and the collection is reasonable for
                    purposes related to investigating a breach of an agreement
                    or a contravention of the laws of Canada or a province
                  </Terms.ListItem>
                  <Terms.ListItem>
                    If disclosure is required to comply with a subpoena,
                    warrant, court order, or rules of the court relating to the
                    production of records
                  </Terms.ListItem>
                  <Terms.ListItem>
                    If it was produced by an individual in the course of their
                    employment, business, or profession and the collection is
                    consistent with the purposes for which the information was
                    produced
                  </Terms.ListItem>
                  <Terms.ListItem>
                    If the collection is solely for journalistic, artistic, or
                    literary purposes
                  </Terms.ListItem>
                  <Terms.ListItem>
                    If the information is publicly available and is specified by
                    the regulations
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="whoshare">
                <Terms.TitleSmall as="h5">
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> We may share information in specific
                    situations described in this section and/or with the
                    following third parties.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  <b>
                    Vendors, Consultants, and Other Third-Party Service
                    Providers.
                  </b>
                  We may share your data with third-party vendors, service
                  providers, contractors, or agents ("<b>third parties</b>") who
                  perform services for us or on our behalf and require access to
                  such information to do that work. We have contracts in place
                  with our third parties, which are designed to help safeguard
                  your personal information. This means that they cannot do
                  anything with your personal information unless we have
                  instructed them to do it. They will also not share your
                  personal information with any organization apart from us. They
                  also commit to protect the data they hold on our behalf and to
                  retain it for the period we instruct. The third parties we may
                  share personal information with are as follows:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    <b>Affiliate Marketing Programs</b>
                  </Terms.ListItem>
                  <Terms.Text>Amazon Affiliation</Terms.Text>
                  <Terms.ListItem>
                    <b>Cloud Computing Services</b>
                  </Terms.ListItem>
                  <Terms.Text>Amazon Web Services (AWS)</Terms.Text>
                  <Terms.ListItem>
                    <b>Invoice and Billing</b>
                  </Terms.ListItem>
                  <Terms.Text>Android Pay and Apple Pay</Terms.Text>
                  <Terms.ListItem>
                    <b>User Account Registration and Authentication</b>
                  </Terms.ListItem>
                  <Terms.Text>Firebase Authentication</Terms.Text>
                  <Terms.ListItem>
                    <b>Web and Mobile Analytics</b>
                  </Terms.ListItem>
                  <Terms.Text>Google Analytics for Firebase</Terms.Text>
                  <Terms.ListItem>
                    <b>Website Performance Monitoring</b>
                  </Terms.ListItem>
                  <Terms.Text>
                    Firebase Performance Monitoring and Crashlytics
                  </Terms.Text>
                </Terms.List>
                <Terms.Text>
                  We also may need to share your personal information in the
                  following situations:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    <b>Business Transfers.</b> We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="sociallogins">
                <Terms.TitleSmall as="h5">
                  5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> If you choose to register or log in to our
                    Services using a social media account, we may have access to
                    certain information about you.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  Our Services offer you the ability to register and log in
                  using your third-party social media account details (like your
                  Facebook or Twitter logins). Where you choose to do this, we
                  will receive certain profile information about you from your
                  social media provider. The profile information we receive may
                  vary depending on the social media provider concerned, but
                  will often include your name, email address, friends list, and
                  profile picture, as well as other information you choose to
                  make public on such a social media platform.
                </Terms.Text>
                <Terms.Text>
                  We will use the information we receive only for the purposes
                  that are described in this privacy notice or that are
                  otherwise made clear to you on the relevant Services. Please
                  note that we do not control, and are not responsible for,
                  other uses of your personal information by your third-party
                  social media provider. We recommend that you review their
                  privacy notice to understand how they collect, use, and share
                  your personal information, and how you can set your privacy
                  preferences on their sites and apps.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="inforetain">
                <Terms.TitleSmall as="h5">
                  6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> We keep your information for as long as
                    necessary to fulfill the purposes outlined in this privacy
                    notice unless otherwise required by law.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this privacy notice,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting, or other legal requirements). No
                  purpose in this notice will require us keeping your personal
                  information for longer than the period of time in which users
                  have an account with us.
                </Terms.Text>
                <Terms.Text>
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  such information, or, if this is not possible (for example,
                  because your personal information has been stored in backup
                  archives), then we will securely store your personal
                  information and isolate it from any further processing until
                  deletion is possible.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="infosafe">
                <Terms.TitleSmall as="h5">
                  7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> We aim to protect your personal information
                    through a system of organizational and technical security
                    measures.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  We have implemented appropriate and reasonable technical and
                  organizational security measures designed to protect the
                  security of any personal information we process. However,
                  despite our safeguards and efforts to secure your information,
                  no electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Services is at your own risk. You should only
                  access the Services within a secure environment.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="infominors">
                <Terms.TitleSmall as="h5">
                  8. DO WE COLLECT INFORMATION FROM MINORS?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> We do not knowingly collect data from or
                    market to children under 18 years of age.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  We do not knowingly solicit data from or market to children
                  under 18 years of age. By using the Services, you represent
                  that you are at least 18 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent's
                  use of the Services. If we learn that personal information
                  from users less than 18 years of age has been collected, we
                  will deactivate the account and take reasonable measures to
                  promptly delete such data from our records. If you become
                  aware of any data we may have collected from children under
                  age 18, please contact us at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  .
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="privacyrights">
                <Terms.TitleSmall as="h5">
                  9. WHAT ARE YOUR PRIVACY RIGHTS?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> In some regions, such as Canada, you have
                    rights that allow you greater access to and control over
                    your personal information. You may review, change, or
                    terminate your account at any time.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  In some regions (like Canada), you have certain rights under
                  applicable data protection laws. These may include the right
                  (i) to request access and obtain a copy of your personal
                  information, (ii) to request rectification or erasure; (iii)
                  to restrict the processing of your personal information; (iv)
                  if applicable, to data portability; and (v) not to be subject
                  to automated decision-making. In certain circumstances, you
                  may also have the right to object to the processing of your
                  personal information. You can make such a request by
                  contacting us by using the contact details provided in the
                  section "
                  <a href="#contact">
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </a>
                  " below.
                </Terms.Text>
                <Terms.Text>
                  We will consider and act upon any request in accordance with
                  applicable data protection laws.
                </Terms.Text>
                <Terms.Text id="withdrawconsent">
                  <b>
                    <u>Withdrawing your consent:</u>
                  </b>{" "}
                  If we are relying on your consent to process your personal
                  information, which may be express and/or implied consent
                  depending on the applicable law, you have the right to
                  withdraw your consent at any time. You can withdraw your
                  consent at any time by contacting us by using the contact
                  details provided in the section "
                  <a href="#contact">
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </a>
                  " below.
                </Terms.Text>
                <Terms.Text>
                  However, please note that this will not affect the lawfulness
                  of the processing before its withdrawal nor, when applicable
                  law allows, will it affect the processing of your personal
                  information conducted in reliance on lawful processing grounds
                  other than consent.
                </Terms.Text>
                <Terms.Subtitle>Account Information</Terms.Subtitle>
                <Terms.Text>
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Log in to your account settings and update your user
                    account.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, we may retain some information in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our legal terms and/or comply with
                  applicable legal requirements.
                </Terms.Text>
                <Terms.Text>
                  If you have questions or comments about your privacy rights,
                  you may email us at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  .
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="DNT">
                <Terms.TitleSmall as="h5">
                  10. CONTROLS FOR DO-NOT-TRACK FEATURES
                </Terms.TitleSmall>
                <Terms.Text>
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track ("DNT") feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. At this stage no uniform technology standard for
                  recognizing and implementing DNT signals has been finalized.
                  As such, we do not currently respond to DNT browser signals or
                  any other mechanism that automatically communicates your
                  choice not to be tracked online. If a standard for online
                  tracking is adopted that we must follow in the future, we will
                  inform you about that practice in a revised version of this
                  privacy notice.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="uslaws">
                <Terms.TitleSmall as="h5">
                  11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> If you are a resident of California,
                    Colorado, Connecticut, Utah or Virginia, you are granted
                    specific rights regarding access to your personal
                    information.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  <b>What categories of personal information do we collect?</b>
                </Terms.Text>
                <Terms.Text>
                  We have collected the following categories of personal
                  information in the past twelve (12) months:
                </Terms.Text>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Examples</th>
                      <th>Collected</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A. Identifiers</td>
                      <td>
                        Contact details, such as real name, alias, postal
                        address, telephone or mobile contact number, unique
                        personal identifier, online identifier, Internet
                        Protocol address, email address, and account name
                      </td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <td>
                        B. Personal information as defined in the California
                        Customer Records statute
                      </td>
                      <td>
                        Name, contact information, education, employment,
                        employment history, and financial information
                      </td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <td>
                        C. Protected classification characteristics under state
                        or federal law
                      </td>
                      <td>Gender and date of birth</td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <td>D. Commercial information</td>
                      <td>
                        Transaction information, purchase history, financial
                        details, and payment information
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>E. Biometric information</td>
                      <td>Fingerprints and voiceprints</td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>F. Internet or other similar network activity</td>
                      <td>
                        Browsing history, search history, online behavior,
                        interest data, and interactions with our and other
                        websites, applications, systems, and advertisements
                      </td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <td>G. Geolocation data</td>
                      <td>Device location</td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>
                        H. Audio, electronic, visual, thermal, olfactory, or
                        similar information
                      </td>
                      <td>
                        Images and audio, video or call recordings created in
                        connection with our business activities
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>I. Professional or employment-related information</td>
                      <td>
                        Business contact details in order to provide you our
                        Services at a business level or job title, work history,
                        and professional qualifications if you apply for a job
                        with us
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>J. Education Information</td>
                      <td>Student records and directory information</td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>
                        K. Inferences drawn from collected personal information
                      </td>
                      <td>
                        Inferences drawn from any of the collected personal
                        information listed above to create a profile or summary
                        about, for example, an individual’s preferences and
                        characteristics
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td>L. Sensitive personal Information</td>
                      <td></td>
                      <td>NO</td>
                    </tr>
                  </tbody>
                </table>
                <Terms.Text>
                  We will use and retain the collected personal information as
                  needed to provide the Services or for:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Category A - As long as the user has an account with us
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Category B - As long as the user has an account with us
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Category C - As long as the user has an account with us
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Category F - As long as the user has an account with us
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  We may also collect other personal information outside of
                  these categories through instances where you interact with us
                  in person, online, or by phone or mail in the context of:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Receiving help through our customer support channels;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Participation in customer surveys or contests; and
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Facilitation in the delivery of our Services and to respond
                    to your inquiries.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  <b>How do we use and share your personal information?</b>
                </Terms.Text>
                <Terms.Text>
                  Learn about how we use your personal information in the
                  section, "
                  <a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a>"
                </Terms.Text>
                <Terms.Text>
                  <b>Will your information be shared with anyone else?</b>
                </Terms.Text>
                <Terms.Text>
                  We may disclose your personal information with our service
                  providers pursuant to a written contract between us and each
                  service provider. Learn more about how we disclose personal
                  information to in the section, "
                  <a href="#whoshare">
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </a>
                  "
                </Terms.Text>
                <Terms.Text>
                  We may use your personal information for our own business
                  purposes, such as for undertaking internal research for
                  technological development and demonstration. This is not
                  considered to be "selling" of your personal information.
                </Terms.Text>
                <Terms.Text>
                  We have not sold or shared any personal information to third
                  parties for a business or commercial purpose in the preceding
                  twelve (12) months. We have disclosed the following categories
                  of personal information to third parties for a business or
                  commercial purpose in the preceding twelve (12) months:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>Category A. Identifiers</Terms.ListItem>
                  <Terms.ListItem>
                    Category C. Characteristics of protected classifications
                    under state or federal law
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Category F. Internet or other electronic network activity
                    information
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  The categories of third parties to whom we disclosed personal
                  information for a business or commercial purpose can be found
                  under{" "}
                  <a href="#whoshare">
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </a>
                </Terms.Text>
                <Terms.Subtitle>California Residents</Terms.Subtitle>
                <Terms.Text>
                  California Civil Code Section 1798.83, also known as the
                  "Shine The Light" law permits our users who are California
                  residents to request and obtain from us, once a year and free
                  of charge, information about categories of personal
                  information (if any) we disclosed to third parties for direct
                  marketing purposes and the names and addresses of all third
                  parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California
                  resident and would like to make such a request, please submit
                  your request in writing to us using the contact information
                  provided below.
                </Terms.Text>
                <Terms.Text>
                  If you are under 18 years of age, reside in California, and
                  have a registered account with the Services, you have the
                  right to request removal of unwanted data that you publicly
                  post on the Services. To request removal of such data, please
                  contact us using the contact information provided below and
                  include the email address associated with your account and a
                  statement that you reside in California. We will make sure the
                  data is not publicly displayed on the Services, but please be
                  aware that the data may not be completely or comprehensively
                  removed from all our systems (e.g., backups, etc.).
                </Terms.Text>
                <Terms.Text>
                  <b>
                    <u>CCPA Privacy Notice</u>
                  </b>
                </Terms.Text>
                <Terms.Text>
                  This section applies only to California residents. Under the
                  California Consumer Privacy Act (CCPA), you have the rights
                  listed below.
                </Terms.Text>
                <Terms.Text>
                  The California Code of Regulations defines a "residents" as:
                </Terms.Text>
                <Terms.Text>
                  (1) every individual who is in the State of California for
                  other than a temporary or transitory purpose and
                </Terms.Text>
                <Terms.Text>
                  (2) every individual who is domiciled in the State of
                  California who is outside the State of California for a
                  temporary or transitory purpose
                </Terms.Text>
                <Terms.Text>
                  All other individuals are defined as "non-residents."
                </Terms.Text>
                <Terms.Text>
                  If this definition of "resident" applies to you, we must
                  adhere to certain rights and obligations regarding your
                  personal information.
                </Terms.Text>
                <Terms.Text>
                  <b>Your rights with respect to your personal data</b>
                </Terms.Text>
                <Terms.Text>
                  <u>
                    Right to request deletion of the data -- Request to delete
                  </u>
                </Terms.Text>
                <Terms.Text>
                  You can ask for the deletion of your personal information. If
                  you ask us to delete your personal information, we will
                  respect your request and delete your personal information,
                  subject to certain exceptions provided by law, such as (but
                  not limited to) the exercise by another consumer of his or her
                  right to free speech, our compliance requirements resulting
                  from a legal obligation, or any processing that may be
                  required to protect against illegal activities.
                </Terms.Text>
                <Terms.Text>
                  <u>Right to be informed -- Request to know</u>
                </Terms.Text>
                <Terms.Text>
                  Depending on the circumstances, you have a right to know:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    whether we collect and use your personal information;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    the categories of personal information that we collect;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    the purposes for which the collected personal information is
                    used;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    whether we sell or share personal information to third
                    parties;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    the categories of personal information that we sold, shared,
                    or disclosed for a business purpose;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    the categories of third parties to whom the personal
                    information was sold, shared, or disclosed for a business
                    purpose;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    the business or commercial purpose for collecting, selling,
                    or sharing personal information; and
                  </Terms.ListItem>
                  <Terms.ListItem>
                    the specific pieces of personal information we collected
                    about you.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  In accordance with applicable law, we are not obligated to
                  provide or delete consumer information that is de-identified
                  in response to a consumer request or to re-identify individual
                  data to verify a consumer request.
                </Terms.Text>
                <Terms.Text>
                  <u>
                    Right to Non-Discrimination for the Exercise of a Consumer's
                    Privacy Rights
                  </u>
                </Terms.Text>
                <Terms.Text>
                  We will not discriminate against you if you exercise your
                  privacy rights.
                </Terms.Text>
                <Terms.Text>
                  <u>
                    Right to Limit Use and Disclosure of Sensitive Personal
                    Information
                  </u>
                </Terms.Text>
                <Terms.Text>
                  We do not process consumer's sensitive personal information.
                </Terms.Text>
                <Terms.Text>
                  <u>Verification process</u>
                </Terms.Text>
                <Terms.Text>
                  Upon receiving your request, we will need to verify your
                  identity to determine you are the same person about whom we
                  have the information in our system. These verification efforts
                  require us to ask you to provide information so that we can
                  match it with information you have previously provided us. For
                  instance, depending on the type of request you submit, we may
                  ask you to provide certain information so that we can match
                  the information you provide with the information we already
                  have on file, or we may contact you through a communication
                  method (e.g., phone or email) that you have previously
                  provided to us. We may also use other verification methods as
                  the circumstances dictate.
                </Terms.Text>
                <Terms.Text>
                  We will only use personal information provided in your request
                  to verify your identity or authority to make the request. To
                  the extent possible, we will avoid requesting additional
                  information from you for the purposes of verification.
                  However, if we cannot verify your identity from the
                  information already maintained by us, we may request that you
                  provide additional information for the purposes of verifying
                  your identity and for security or fraud-prevention purposes.
                  We will delete such additionally provided information as soon
                  as we finish verifying you.
                </Terms.Text>
                <Terms.Text>
                  <u>Other privacy rights</u>
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    You may object to the processing of your personal
                    information.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    You may request correction of your personal data if it is
                    incorrect or no longer relevant, or ask to restrict the
                    processing of the information.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    You can designate an authorized agent to make a request
                    under the CCPA on your behalf. We may deny a request from an
                    authorized agent that does not submit proof that they have
                    been validly authorized to act on your behalf in accordance
                    with the CCPA.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    You may request to opt out from future selling or sharing of
                    your personal information to third parties. Upon receiving
                    an opt-out request, we will act upon the request as soon as
                    feasibly possible, but no later than fifteen (15) days from
                    the date of the request submission.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  To exercise these rights, you can contact us by email at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  , or by referring to the contact details at the bottom of this
                  document. If you have a complaint about how we handle your
                  data, we would like to hear from you.
                </Terms.Text>
                <Terms.Subtitle>Colorado Residents</Terms.Subtitle>
                <Terms.Text>
                  This section applies only to Colorado residents. Under the
                  Colorado Privacy Act (CPA), you have the rights listed below.
                  However, these rights are not absolute, and in certain cases,
                  we may decline your request as permitted by law.
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Right to be informed whether or not we are processing your
                    personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to access your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to correct inaccuracies in your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to request deletion of your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to obtain a copy of the personal data you previously
                    shared with us
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to opt out of the processing of your personal data if
                    it is used for targeted advertising, the sale of personal
                    data, or profiling in furtherance of decisions that produce
                    legal or similarly significant effects ("profiling")
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  To submit a request to exercise these rights described above,
                  please email{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  .
                </Terms.Text>
                <Terms.Text>
                  If we decline to take action regarding your request and you
                  wish to appeal our decision, please email us at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  . Within forty-five (45) days of receipt of an appeal, we will
                  inform you in writing of any action taken or not taken in
                  response to the appeal, including a written explanation of the
                  reasons for the decisions.
                </Terms.Text>
                <Terms.Subtitle>Connecticut Residents</Terms.Subtitle>
                <Terms.Text>
                  This section applies only to Connecticut residents. Under the
                  Connecticut Data Privacy Act (CTDPA), you have the rights
                  listed below. However, these rights are not absolute, and in
                  certain cases, we may decline your request as permitted by
                  law.
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Right to be informed whether or not we are processing your
                    personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to access your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to correct inaccuracies in your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to request deletion of your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to obtain a copy of the personal data you previously
                    shared with us
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to opt out of the processing of your personal data if
                    it is used for targeted advertising, the sale of personal
                    data, or profiling in furtherance of decisions that produce
                    legal or similarly significant effects ("profiling")
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  To submit a request to exercise these rights described above,
                  please email{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  .
                </Terms.Text>
                <Terms.Text>
                  If we decline to take action regarding your request and you
                  wish to appeal our decision, please email us at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  . Within sixty (60) days of receipt of an appeal, we will
                  inform you in writing of any action taken or not taken in
                  response to the appeal, including a written explanation of the
                  reasons for the decisions.
                </Terms.Text>
                <Terms.Subtitle>Utah Residents</Terms.Subtitle>
                <Terms.Text>
                  This section applies only to Utah residents. Under the Utah
                  Consumer Privacy Act (UCPA), you have the rights listed below.
                  However, these rights are not absolute, and in certain cases,
                  we may decline your request as permitted by law.
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Right to be informed whether or not we are processing your
                    personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to access your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to request deletion of your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to obtain a copy of the personal data you previously
                    shared with us
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to opt out of the processing of your personal data if
                    it is used for targeted advertising or the sale of personal
                    data
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  To submit a request to exercise these rights described above,
                  please email{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  .
                </Terms.Text>
                <Terms.Subtitle>Virginia Residents</Terms.Subtitle>
                <Terms.Text>
                  Under the Virginia Consumer Data Protection Act (VCDPA):
                </Terms.Text>
                <Terms.Text>
                  "Consumer" means a natural person who is a resident of the
                  Commonwealth acting only in an individual or household
                  context. It does not include a natural person acting in a
                  commercial or employment context.
                </Terms.Text>
                <Terms.Text>
                  "Personal data" means any information that is linked or
                  reasonably linkable to an identified or identifiable natural
                  person. "Personal data" does not include de-identified data or
                  publicly available information.
                </Terms.Text>
                <Terms.Text>
                  "Sale of personal data" means the exchange of personal data
                  for monetary consideration.
                </Terms.Text>
                <Terms.Text>
                  If this definition of "consumer" applies to you, we must
                  adhere to certain rights and obligations regarding your
                  personal data.
                </Terms.Text>
                <Terms.Text>
                  <u>Your rights with respect to your personal data</u>
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Right to be informed whether or not we are processing your
                    personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to access your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to correct inaccuracies in your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to request deletion of your personal data
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to obtain a copy of the personal data you previously
                    shared with us
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Right to opt out of the processing of your personal data if
                    it is used for targeted advertising, the sale of personal
                    data, or profiling in furtherance of decisions that produce
                    legal or similarly significant effects ("profiling")
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  <u>Exercise your rights provided under the Virginia VCDPA</u>
                </Terms.Text>
                <Terms.Text>
                  You may contact us by email at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  .
                </Terms.Text>
                <Terms.Text>
                  If you are using an authorized agent to exercise your rights,
                  we may deny a request if the authorized agent does not submit
                  proof that they have been validly authorized to act on your
                  behalf.
                </Terms.Text>
                <Terms.Text>
                  <u>Verification process</u>
                </Terms.Text>
                <Terms.Text>
                  We may request that you provide additional information
                  reasonably necessary to verify you and your consumer's
                  request. If you submit the request through an authorized
                  agent, we may need to collect additional information to verify
                  your identity before processing your request.
                </Terms.Text>
                <Terms.Text>
                  Upon receiving your request, we will respond without undue
                  delay, but in all cases, within forty-five (45) days of
                  receipt. The response period may be extended once by
                  forty-five (45) additional days when reasonably necessary. We
                  will inform you of any such extension within the initial
                  45-day response period, together with the reason for the
                  extension.
                </Terms.Text>
                <Terms.Text>
                  <u>Right to appeal</u>
                </Terms.Text>
                <Terms.Text>
                  If we decline to take action regarding your request, we will
                  inform you of our decision and reasoning behind it. If you
                  wish to appeal our decision, please email us at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  . Within sixty (60) days of receipt of an appeal, we will
                  inform you in writing of any action taken or not taken in
                  response to the appeal, including a written explanation of the
                  reasons for the decisions. If your appeal is denied, you may
                  contact the Attorney General to submit a complaint.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="policyupdates">
                <Terms.TitleSmall as="h5">
                  12. DO WE MAKE UPDATES TO THIS NOTICE?
                </Terms.TitleSmall>
                <Terms.Text>
                  <i>
                    <b>In Short:</b> Yes, we will update this notice as
                    necessary to stay compliant with relevant laws.
                  </i>
                </Terms.Text>
                <Terms.Text>
                  We may update this privacy notice from time to time. The
                  updated version will be indicated by an updated "Revised" date
                  and the updated version will be effective as soon as it is
                  accessible. If we make material changes to this privacy
                  notice, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a
                  notification. We encourage you to review this privacy notice
                  frequently to be informed of how we are protecting your
                  information.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="contact">
                <Terms.TitleSmall as="h5">
                  13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </Terms.TitleSmall>
                <Terms.Text>
                  If you have any questions about this Privacy Policy, You can
                  contact us:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    By Email:{" "}
                    <a href="mailto: privacy@monsaiapp.com">
                      privacy@monsaiapp.com
                    </a>
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="request">
                <Terms.TitleSmall as="h5">
                  14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </Terms.TitleSmall>
                <Terms.Text>
                  You have the right to request access to the personal
                  information we collect from you, change that information, or
                  delete it. To request to review, update, or delete your
                  personal information, contact us by email at{" "}
                  <a href="mailto: privacy@monsaiapp.com">
                    privacy@monsaiapp.com
                  </a>
                  .
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  )
}
